/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";

import { Field, reduxForm, reset } from "redux-form";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import Loading from "../../components/Loading";

import CustomizedInputs from "../../components/UIform";
import inputPhone from "../../components/UIform/inputPhone";
import Button from "../../components/Button";
import ModalComponent from "../../components/ModalComponent";
import { Redirect } from "react-router-dom";

const dataNames = [
	"fondy",
	"liqPay",
	"wayForPay",
	"yandex",
	"portmone",
	"monobank",
];
const required = (value) => (value ? undefined : "Це обов'язкове поле");

let PayCards = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [fieldName, setFieldName] = useState(null);
	const [submitData, setSubmitData] = useState(null);

	const { handleSubmit, loadStatus, checked, shops, reset, pristine } = props;

	const handleClickOpen = (data) => {
		const { valid } = props;

		if (valid) {
			setOpen(true);
			setIsLoading(true);
			setSubmitData(data);
		}
	};

	const handleClose = () => {
		setOpen(false);
		setIsSubmit(false);
	};

	const handleClickSubmit = () => {
		setOpen(false);
		setIsSubmit(true);
	};

	useEffect(() => {
		if (isLoading && isSubmit && !open) {
			const { handleSubmit, valid } = props;

			console.log("valid", valid);

			props.sendData(submitData);
			reset();
			setSubmitData(null);

			console.log("Вы подтвердили");
		}

		if (isLoading && !isSubmit && !open) {
			console.log("Вы отменили");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmit, open, isLoading]);

	const cards = shops.cardPaymentSystem;

	if (cards) {
		// eslint-disable-next-line array-callback-return
		dataNames.map((name) => {
			for (let key in cards) {
				if (key === name) {
					if (!fieldName || fieldName !== name) {
						setFieldName(name);
					}

					// initialValues = cards[name];
				}
			}
		});
	}

	if (!fieldName || !cards) {
		return <Redirect to="/" />;
	}

	return (
		<Grid className={classes.container} container>
			<Grid item xs={12}>
				<Typography className={classes.title} variant="h6">
					{t("cardPayments.paymentSystems")}
				</Typography>
				<ModalComponent
					title={t("modals.confirm")}
					description={t("modals.confirmSave")}
					open={open}
					handleClose={handleClose}
					handleClickSubmit={handleClickSubmit}
				/>

				<form
					className={classes.form}
					onSubmit={handleSubmit}
					initialValues={props.initialValues}
				>
					<Grid className={classes.payItemContainer} container>
						{fieldName === "portmone" && (
							<div className={classes.payItem}>
								<div className={classes.payItemWrap}>
									<label htmlFor="portmone" className={classes.payFieldWrapper}>
										<div className={classes.payTitle}>Portmone</div>
									</label>
									<div className={classes.payItemsField}>
										<div className={classes.payFieldWrap}>
											<label className={classes.payFieldText}>PayeeId</label>
											<Field
												className={classes.payItemField}
												type="text"
												label=""
												name="payeeId"
												component={CustomizedInputs}
												disabled={!checked ? true : false}
												placeholder={`${t("cardPayments.enter")} PayeeId`}
												validate={[required]}
											/>
										</div>
									</div>
								</div>
							</div>
						)}
						{fieldName === "fondy" && (
							<div className={classes.payItem}>
								<div className={classes.payItemWrap}>
									<label htmlFor="fondy" className={classes.payFieldWrapper}>
										<div className={classes.payTitle}>Fondy</div>
									</label>
									<div className={classes.payItemsField}>
										<div className={classes.payFieldWrap}>
											<label className={classes.payFieldText}>Merchant</label>
											<Field
												className={classes.payItemField}
												type="text"
												label=""
												name="merchant"
												component={CustomizedInputs}
												disabled={!checked ? true : false}
												placeholder={`${t("cardPayments.enter")} Merchant`}
												validate={[required]}
											/>
										</div>
									</div>
								</div>
							</div>
						)}
						{fieldName === "liqPay" && (
							<div className={classes.payItem}>
								<div className={classes.payItemWrap}>
									<label htmlFor="liqPay" className={classes.payFieldWrapper}>
										<div className={classes.payTitle}>LiqPay</div>
									</label>
									<div className={classes.payItemsField}>
										<div className={classes.payFieldWrap}>
											<label className={classes.payFieldText}>
												Private key
											</label>
											<Field
												className={classes.payItemField}
												type="text"
												label=""
												name="privateKey"
												component={CustomizedInputs}
												disabled={!checked ? true : false}
												placeholder={`${t("cardPayments.enter")} Private key`}
												validate={[required]}
											/>
										</div>
										<div className={classes.payFieldWrap}>
											<label className={classes.payFieldText}>Public key</label>
											<Field
												className={classes.payItemField}
												type="text"
												label=""
												name="publicKey"
												component={CustomizedInputs}
												disabled={!checked ? true : false}
												placeholder={`${t("cardPayments.enter")} Public key`}
												validate={[required]}
											/>
										</div>
									</div>
								</div>
							</div>
						)}
						{fieldName === "wayForPay" && (
							<div className={classes.payItem}>
								<div className={classes.payItemWrap}>
									<label htmlFor="liqPay" className={classes.payFieldWrapper}>
										<div className={classes.payTitle}>WayForPay</div>
									</label>
									<div className={classes.payItemsField}>
										<div className={classes.payFieldWrap}>
											<label className={classes.payFieldText}>
												Merchant domain
											</label>
											<Field
												className={classes.payItemField}
												type="text"
												label=""
												name="merchantDomain"
												component={CustomizedInputs}
												disabled={!checked ? true : false}
												placeholder={`${t(
													"cardPayments.enter"
												)} merchantDomain`}
												validate={[required]}
											/>
										</div>
										<div className={classes.payFieldWrap}>
											<label className={classes.payFieldText}>
												Merchant login
											</label>
											<Field
												className={classes.payItemField}
												type="text"
												label=""
												name="merchantLogin"
												component={CustomizedInputs}
												disabled={!checked ? true : false}
												placeholder={`${t(
													"cardPayments.enter"
												)} Merchant login`}
												validate={[required]}
											/>
										</div>
										<div className={classes.payFieldWrap}>
											<label className={classes.payFieldText}>
												Merchant secret key
											</label>
											<Field
												className={classes.payItemField}
												type="text"
												label=""
												name="merchantSecretKey"
												component={CustomizedInputs}
												disabled={!checked ? true : false}
												placeholder={`${t(
													"cardPayments.enter"
												)} Merchant secret key`}
												validate={[required]}
											/>
										</div>
									</div>
								</div>
							</div>
						)}
						{fieldName === "yandex" && (
							<div className={classes.payItem}>
								<div className={classes.payItemWrap}>
									<label htmlFor="yandex" className={classes.payFieldWrapper}>
										<div className={classes.payTitle}>Yandex</div>
									</label>
									<div className={classes.payItemsField}>
										<div className={classes.payFieldWrap}>
											<label className={classes.payFieldText}>
												Mobile Sdk key
											</label>
											<Field
												className={classes.payItemField}
												type="text"
												label=""
												name="mobileSdkKey"
												component={CustomizedInputs}
												disabled={!checked ? true : false}
												placeholder={`${t(
													"cardPayments.enter"
												)} Mobile Sdk Key`}
												validate={[required]}
											/>
										</div>
										<div className={classes.payFieldWrap}>
											<label className={classes.payFieldText}>Secret key</label>
											<Field
												className={classes.payItemField}
												type="text"
												label=""
												name="secretKey"
												component={CustomizedInputs}
												disabled={!checked ? true : false}
												placeholder={`${t("cardPayments.enter")} Secret key`}
												validate={[required]}
											/>
										</div>
										<div className={classes.payFieldWrap}>
											<label className={classes.payFieldText}>Shop Id</label>
											<Field
												className={classes.payItemField}
												type="number"
												label=""
												name="shopId"
												component={inputPhone}
												disabled={!checked ? true : false}
												placeholder={`${t("cardPayments.enter")} shop id`}
												validate={[required]}
											/>
										</div>
										<div className={classes.payFieldWrap}>
											<label className={classes.payFieldText}>
												Yandex Money
											</label>
											<Field
												className={classes.payItemField}
												type="number"
												label=""
												name="yandexMoney"
												component={inputPhone}
												disabled={!checked ? true : false}
												placeholder={`${t("cardPayments.enter")} Yandex Money`}
												validate={[required]}
											/>
										</div>
									</div>
								</div>
							</div>
						)}
						{fieldName === "monobank" && (
							<div className={classes.payItem}>
								<div className={classes.payItemWrap}>
									<label htmlFor="monobank" className={classes.payFieldWrapper}>
										<div className={classes.payTitle}>Monobank</div>
									</label>
									<div className={classes.payItemsField}>
										<div className={classes.payFieldWrap}>
											<label className={classes.payFieldText}>
												Confirmation url
											</label>
											<Field
												className={classes.payItemField}
												type="text"
												label=""
												name="confirmationUrl"
												component={CustomizedInputs}
												disabled={!checked ? true : false}
												placeholder={`${t(
													"cardPayments.enter"
												)} Confirmation url`}
												validate={[required]}
											/>
										</div>
										<div className={classes.payFieldWrap}>
											<label className={classes.payFieldText}>Token</label>
											<Field
												className={classes.payItemField}
												type="text"
												label=""
												name="token"
												component={CustomizedInputs}
												disabled={!checked ? true : false}
												placeholder={`${t("cardPayments.enter")} Token`}
												validate={[required]}
											/>
										</div>
									</div>
								</div>
							</div>
						)}
					</Grid>

					<Grid className={classes.buttonContainer} item xs={12}>
						<Button
							className={classes.button}
							disabled={!checked && pristine}
							variant="contained"
							text={
								loadStatus ? (
									<Loading size={22} color={"#fff"} />
								) : (
									t("buttons.save")
								)
							}
							color="primary"
							handleClick={handleSubmit((data) => handleClickOpen(data))}
						/>
					</Grid>
				</form>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: 20,
		marginBottom: 5,
	},
	title: {
		fontSize: 16,
		fontWeight: "bold",
	},
	payItemContainer: {
		display: "flex",
		flexDirection: "column",
	},
	form: {
		overflowY: "auto",
		height: 420,
	},
	payItem: {
		display: "flex",
		flexDirection: "column",
		marginTop: 16,
		lineHeight: "57px",
		padding: "0 16px",
		background: "#FFFFFF",
		boxSizing: "border-box",
		boxShadow: "0 7px 20px -5px rgba(0, 0, 0, .1)",
		borderRadius: 5,
	},
	payFieldWrapper: {
		height: 57,
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		cursor: "pointer",
	},
	payTitle: {
		fontSize: 16,
		lineHeight: "19px",
		fontWeight: 500,
	},
	radioField: {
		width: 16,
		height: 16,
		color: "#476282",
		border: "1px solid #476282",
		cursor: "pointer",
		"&:hover": {
			border: "1px solid #326DFF;",
		},
	},
	radio: {
		color: "#476282",
	},
	payItemField: {
		[theme.breakpoints.down("940")]: {
			width: "100%",
		},
		"& input.MuiInput-input": {
			width: 328,
			height: 45,
			color: "#476282",
			padding: "6px 16px",
			boxSizing: "border-box",
			[theme.breakpoints.down("940")]: {
				width: "100%",
			},
		},
		"& .MuiInput-underline:before": {
			content: "none",
		},
		"& .MuiInput-underline:after": {
			content: "none",
		},
		"& .MuiFormHelperText-root.Mui-error": {
			paddingTop: 5,
			color: "red",
		},
		"& .MuiInput-formControl": {
			border: "1px solid #476282",
			borderRadius: 5,
		},
		"& .MuiInput-formControl.Mui-error": {
			border: "1px solid red",
		},
	},
	payFieldWrap: {
		display: "block",
		width: 328,
		marginBottom: 16,
		marginRight: 16,
		[theme.breakpoints.down("940")]: {
			width: "100%",
			marginRight: 0,
			"& > div": {
				width: "100%",
			},
		},
	},
	payFieldText: {
		marginBottom: 8,
		display: "block",
		fontSize: 16,
		lineHeight: "24px",
		color: "black",
	},
	payItemsField: {
		display: "flex",
		flexWrap: "wrap",
	},
	buttonContainer: {
		width: 180,
		margin: "0 auto",
		marginTop: 10,
	},
	button: {
		marginTop: 16,
		textTransform: "none",
		// backgroundColor: 'red',
		"& .MuiButtonBase-root.Mui-disabled": {
			// backgroundColor: 'red',
		},
	},
}));

const afterSubmit = (result, dispatch) => dispatch(reset("PayCards"));

const mapStateToProps = (state, props) => {
	let initialValues = {};
	let cards = props.shops.cardPaymentSystem;
	let city = props.city;

	dataNames.map((name) => {
		if (cards[name]) initialValues = city[name] ? cards[name] : cards[name];
	});

	return { initialValues };
};

PayCards = connect(
	mapStateToProps,
	null
)(
	reduxForm({
		form: "PayCards",
		onSubmitSuccess: afterSubmit,
		enableReinitialize: true,
	})(PayCards)
);

export default withFirebase(PayCards);
