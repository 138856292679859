import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, withRouter, useHistory } from "react-router-dom";
import { withFirebase } from "../../../firebase";
import ModalAlert from "../../../components/ModalComponent";

import Categories from "./categories";
import { useTranslation } from "react-i18next";

const CategoriesContainer = ({ firebase }) => {
	const { id } = useParams();
	const [lastSegment, setLastSegment] = useState(id >= 0 ? id : null);
	const [adminPermission, setAdminPermission] = useState({});
	const [hiddenCategories, setHiddenCategories] = useState([]);
	const [originHiddenCaregories, setOriginalHiddenCategories] = useState([]);
	const [hiddenProducts, setHiddenProducts] = useState([]);
	const [originHiddenProducts, setOriginHiddenProducts] = useState([]);
	const [preloader, setPreloader] = useState(false);
	const history = useHistory();
	const [nextLocation, setNextLocation] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

	const { t } = useTranslation();

	useEffect(() => {
		getAdminPermission();
		getHiddenProducts();
		getHiddenCategories();
	}, [lastSegment]);

	useEffect(() => {
		if (id !== lastSegment) {
			setLastSegment(id >= 0 ? id : null);
		}
	}, [id]);

	useEffect(() => {
		setHasUnsavedChanges(false);
		if (
			hiddenCategories &&
			hiddenCategories.length !== originHiddenCaregories.length
		) {
			setHasUnsavedChanges(true);
		}
		if (hiddenProducts.length !== originHiddenProducts.length) {
			setHasUnsavedChanges(true);
		}
	}, [hiddenCategories, hiddenProducts]);

	const getAdminPermission = () => {
		setPreloader(true);
		firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/adminPermission`
			)
			.once("value")
			.then((snapshot) => {
				const adminPermission = snapshot.val();
				if (adminPermission) {
					setAdminPermission(adminPermission);
				}
				setPreloader(false);
			});
	};

	const getHiddenProducts = () => {
		setPreloader(true);
		firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}settings/City/${lastSegment}/Category/hiddenProducts`
			)
			.once("value")
			.then((snapshot) => {
				const products = snapshot.val();
				if (products) {
					setOriginHiddenProducts(products);
					setHiddenProducts(products);
				}
				setPreloader(false);
			});
	};

	const getHiddenCategories = () => {
		setPreloader(true);
		firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}settings/City/${lastSegment}/Category/hiddenCategories`
			)
			.once("value")
			.then((snapshot) => {
				const categories = snapshot.val();
				if (categories) {
					setOriginalHiddenCategories(categories);
					setHiddenCategories(categories);
				}
				setPreloader(false);
			});
	};

	const saveData = (type) => {
		const isCategories = type === "hiddenCategories";
		const isProducts = type === "hiddenProducts";

		const setFbCategories = hiddenCategories;
		const setFbProducts = hiddenProducts;

		firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/settings/City/${lastSegment}/Category/${type}`
			)
			.set(isCategories ? setFbCategories : setFbProducts)
			.then(() => {
				setPreloader(false);
				alert("Збережено");
				if (isCategories) {
					getHiddenCategories();
				} else if (isProducts) {
					getHiddenProducts();
				}
			});

		// if (localStorage.getItem("@login") === "osama-sushi") {
		// 	firebase.bd
		// 		.ref(
		// 			`${
		// 				JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
		// 			}/updates`
		// 		)
		// 		.update({ lastUpdateInMenu: Date.now() });
		// }
	};

	useEffect(() => {
		const unblock = history.block((tx) => {
			if (hasUnsavedChanges) {
				setNextLocation(tx.pathname);
				setShowModal(true);
				return false; // Блокируем переход
			}
			return true;
		});

		return () => unblock(); // Убираем блокировку при размонтировании
	}, [hasUnsavedChanges, history]);

	const handleConfirmLeave = () => {
		setShowModal(false);
		setHasUnsavedChanges(false); // Сбрасываем состояние
		history.push(nextLocation); // Выполняем переход
	};

	const handleCancelLeave = () => {
		setShowModal(false);
		setNextLocation(null); // Сбрасываем цель перехода
	};

	return (
		<>
			{showModal && (
				<ModalAlert
					title={t("navigation.modals.wantLeave")}
					description={t("navigation.modals.dontSave")}
					handleProductsClick
					open={showModal}
					handleClose={handleCancelLeave}
					handleClickSubmit={handleConfirmLeave}
				/>
			)}
			<Categories
				adminPermission={adminPermission}
				hiddenCategories={hiddenCategories}
				hiddenProducts={hiddenProducts}
				setHiddenCategories={setHiddenCategories}
				setHiddenProducts={setHiddenProducts}
				// stopList={stopList}
				// products={hiddenProducts}
				loadStatus={preloader}
				saveData={saveData}
			/>
		</>
	);
};

export default withFirebase(withRouter(CategoriesContainer));
